'use strict'

export default function (): void {
  const el = document.querySelector('.trustpilot-widget')

  if (!el) {
    return
  }

  if (document.getElementById('script-trustpilot') === null) {
    const observer = new IntersectionObserver(([e]) => {
      if (!e) {
        return
      }

      if (e.isIntersecting) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.defer = true
        script.id = 'script-trustpilot'
        script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'

        // if the widget is on top of the page, delay it's loading to prevent blocking the rendering of the page
        if (window.scrollY === 0) {
          setTimeout(() => {
            el.appendChild(script)
          }, 3000)
        } else {
          el.appendChild(script)
        }

        observer.unobserve(el)
      }
    })

    observer.observe(el)
  }
}
