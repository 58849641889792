'use strict'

import common from 'assets/themes/vacances-campings/js/page/common/index'
import collapsibleBlock from 'assets/core/js/module/collapsibleBlock'
import readMoreText from 'assets/core/js/module/readMoreText'
import trustPilot from '@ui/ThirdParty/Trustpilot/component'

common.preInit()

document.addEventListener('DOMContentLoaded', function () {
  common.init()

  collapsibleBlock()
  readMoreText()
  trustPilot()
})
